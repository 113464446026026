// Focus Visible Polyfill
import 'focus-visible'

// Internal Modules
// import './modules/parallax'
import './modules/countdown'

function moveBackToTop() {
  if (document.querySelector('.js-viewport-spacer')) {
			if (window.scrollY >= document.documentElement.scrollHeight - window.innerHeight - 5) {
				window.scrollTo(0, 0)
			}
		}
}

document.addEventListener('scroll', () => {
	moveBackToTop()
})


document.querySelector('#toggle').addEventListener('click',function () {
	// alert('test')
	document.querySelector('#toggle').classList.toggle('active')
	document.querySelector('.body').classList.toggle('body_hidden')
	document.querySelector('#navigation').classList.toggle('open')
})

document.querySelectorAll('.menu').forEach((item) => {
	item.addEventListener('click', (event) => {
		document.querySelector('.body').classList.remove('body_hidden')
		document.querySelector('#toggle').classList.remove('active')
		document.querySelector('#navigation').classList.remove('open')
	})
})

// jQuery 
// if ($('.js-viewport-spacer').length > 0) {
// 	if ($(window).scrollTop() >= $(document).height() - $(window).height() - 10) {
//     // alert('yes')
// 		scroll(0, 0)
// 	}
// }

// $(window).bind('scroll', function () {
// 	moveBackToTop()
// })